import React from 'react'
import styled from 'styled-components'
import { bp } from '../styles/colors'
import { colors } from '../styles/colors'

const SectionTitle = (props) => {
    return (
        <Container>
            <Bigtitle>{props.title}</Bigtitle>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 3rem;
    overflow: hidden;

    @media screen and (max-width: ${bp.mobile}) {
        margin-bottom: 1rem;
      
    }
`

const Bigtitle = styled.h1`
    margin-top: 1rem;
    font-size: 10rem;
    font-weight: 900;
    color: ${colors.grey7};
    //transform: translateY(45%);
    @media screen and (max-width: ${bp.mobile}) {
        font-size: 20vw;
    }
`



export default SectionTitle