import React from 'react'
import { Link } from 'gatsby'
import { GreyText, SmallTitle } from "../styles/fonts";
import styled from "styled-components";
import { colors } from '../styles/colors';
import {
    FaArrowRight
} from 'react-icons/fa'
import { bp } from '../styles/colors';

const ServiceCard = (props) => {
    return (
     
        <CardContainer onClick={props.onClick} to={props.link}>
            
            <ContentContainer>
                <IconContainer><img src={props.icon} width="60px" alt="icon"/></IconContainer>
                <ServiceCardTitle>{props.title}</ServiceCardTitle>
                <GreyText style={{minHeight: '7rem'}} >{props.text}</GreyText>
            </ContentContainer>
            <ArrowButtonContainer>Lue lisää <Arrow/></ArrowButtonContainer>
        </CardContainer>
        
    )
}


const Arrow = styled(FaArrowRight)`
    transform: translate(6px, 2px);
    transition: all 0.4s;
`

const ServiceCardTitle = styled(SmallTitle)`
    color: ${colors.black};
    font-size: 1.5rem;
    margin: 1rem 0 1rem 0;

    transition: all 0.4s;

`

const CardContainer = styled(Link)`
    background-color: white;
    box-shadow:      0px 0px 10px rgba(0, 0, 0, 0.05),
  0px 0px 80px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 20rem;
    min-height: 21rem;
    min-width: 1rem;
    transition: transform 200ms ease-in-out;
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;


    &:hover {
        ${Arrow} {
            transform: translate(14px, 2px);
        }

        ${ServiceCardTitle} {
            transform: translateY(-5px);
        }
    }

    @media screen and (max-width: ${bp.mobile}) {
        width: 100%;
        min-width: unset;
        max-width: unset;

        &:hover {
        ${Arrow} {
            transform: translate(6px, 2px);
        }

        ${ServiceCardTitle} {
            transform: none;
        }
    }
    }

`

const IconContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-left: -0.8rem;
    margin-top: -0.5rem;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 1.5rem 1.5rem 1.5rem 1.5rem;
`

const ArrowButtonContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    margin: 0rem 2rem 1.5rem 2rem;
    color: ${colors.grey2};
    font-weight: bold;
    font-size: 1.1rem;
    
`


export default ServiceCard