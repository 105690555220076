import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import { bp } from '../styles/colors'
import ServiceSection from '../components/serviceSection'
import PhotoCollege from '../components/photoCollege'
import { colors } from '../styles/colors'
import Seo from "../components/Seo";
import { graphql, useStaticQuery } from "gatsby";
import { useEffect } from 'react'


const PalvelutPage = () => {

    useEffect( () => {
        
      })


    const serviceSeoPic = useStaticQuery(graphql`
    query serviceSeoPic {
      image: file(relativePath: { eq: "square/Ylitalo-15.jpg" }) {
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
    
    return (
        <Layout>
            <Seo
                title="Palvelut | Ylitalon"
                description="Palveluihimme kuuluvat uudisrakentaminen, saneeraus ja maanrakennus, sekä yhteistyöverkostojen kautta lvi- ja sähkötyöt sekä arkkitehtisuunnittelu."
                keywords={["talon rakentaminen", "rakentaminen", "rakennuspalvelut", "sähkötyöt", "lvi", "arkkitehtisuunnittelu", "uudisrakentaminen", "työkalujen vuokraus", "työkaluvuokraus", "Lahti", "Lahden talousalue", "Porvoo", "Orimattila", "Hollola"]}
                url="https://ylitalon.netlify.app/palvelut"
                image={"https://ylitalon.netlify.app" + serviceSeoPic.image.publicURL}
            />
            <Wrap>
                <InsideWrapper>
            <HeroWrapper>
				<BigTitle>
                    Palvelut
                </BigTitle>
              
                <CollegeWrapper>
                    <PhotoCollege/> 
                </CollegeWrapper>
            </HeroWrapper>
            </InsideWrapper> 
            </Wrap>
            <ServiceSection/>
        </Layout>
    )

}

export default PalvelutPage


const Wrap = styled.section`
  display: flex;
  margin: 0 0rem 0 5rem;
  padding: 0 5rem 0 0;
  justify-content: center;
  overflow: hidden;

  @media screen and (max-width: ${bp.mobile}) {
        margin: 0 0rem 0 1rem;
    }
`

const InsideWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    max-width: ${bp.large};
    width: 100%;
`

const CollegeWrapper = styled.div`
    width: 100%;
`
const HeroWrapper = styled.div`
	width: 100%;
    margin: 10rem 0% 6rem 0%;
    @media screen and (max-width: ${bp.mobile}) {
        width: 100%;
        margin: 10rem 0% 4rem 0%;
    }
`
const BigTitle = styled.h1`
    font-size: 12rem;
    font-weight: 900;
    color: ${colors.grey7};
    width: 100%;
	transform: translate(-10px, 3rem);
    @media screen and (max-width: ${bp.mobile}) {
        font-size: 24vw;
        transform: translate(-5px, 6vw)
    }
`