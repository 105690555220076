import React from 'react'
import styled from 'styled-components'
import { BlackText, MediumTitle, SmallTitle } from '../styles/fonts'
import Button from './button'
import { bp } from '../styles/colors'


const SectionDescription = (props) => {
    return (
        
        <SectionContainer>
            <SmallTitle>{props.smallTitle}</SmallTitle>
            <MediumTitle>{props.mediumTitle}</MediumTitle>
            <BlackText>{props.blackText}</BlackText>
            <ButtonContainer>
                <Button to={props.buttonTo} internal={props.internalButton}>{props.buttonDesc}</Button>
            </ButtonContainer>
        </SectionContainer>
    )
}


const SectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 1rem;
    flex-basis: 35%;
    margin-bottom: 0rem;
    flex-grow: 0.35;

    @media screen and (max-width: ${bp.mobile}) {
       flex-basis: 100%;
    }
`

const ButtonContainer = styled.div`
    margin: 1rem 0 0 0;
`




export default SectionDescription