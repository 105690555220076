import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { bp } from '../styles/colors'

const PhotoCollege = () => {

    const gap = 30

    return(
        <MainContainer gap={gap}>
            <PhotoContainer1 gap={gap}>
                <Photo className='item1' height={300} width={630}> <StaticImage src="../images/college/collageImg-5.jpg" alt="photo1" layout="constrained" /></Photo>
                <Photo className='item2' height={300} width={300}> <StaticImage src="../images/college/collageImg-1.jpg" alt="photo2" layout="constrained" /></Photo>
                <Photo className='item3' height={300} width={300}> <StaticImage src="../images/college/collageImg-3.jpg" alt="photo3" layout="constrained" /></Photo>
            
                <Photo className='item4' height={630} width={420}> <StaticImage src="../images/college/collageImg-2.jpg" alt="photo4" layout="constrained" /></Photo>
            
                <Photo className='item5' height={300} width={450}> <StaticImage src="../images/college/collageImg-6.jpg" alt="photo5" layout="constrained" /></Photo>
                <Photo className='item6' height={300} width={300}> <StaticImage src="../images/college/collageImg-7.jpg" alt="photo6" layout="constrained" /></Photo>
                <Photo className='item7' height={300} width={600}> <StaticImage src="../images/college/collageImg-4.jpg" alt="photo7" layout="constrained" /></Photo>
            
            </PhotoContainer1>
        </MainContainer>
    )
}



const MainContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    //column-gap: ${props => props.gap}px;
    width: 30000px;
    *{
        overflow: hidden;
    }
    @media screen and (max-width: ${bp.mobile}) {
        //column-gap: ${props => props.gap / 2}px;
    }
`
const PhotoContainer1 = styled.div`
    display: grid;
    grid-template-columns: [first] 300px [line2] 300px [line3] auto [line4] auto [line5] 300px [end];
    grid-template-rows: repeat(2, 300px [col-start]);
    gap: 30px 30px;

    @media screen and (max-width: ${bp.mobile}) {
        grid-template-columns: [first] 120px [line2] 120px [line3] auto [line4] auto [line5] 120px [end];
        grid-template-rows: repeat(2, 120px [col-start]);
        gap: 12px 12px;
    }

    .item1{
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .item2{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .item3{
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .item4{
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
        
    }

    .item5{
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .item6{
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .item7{
        grid-column-start: 4;
        grid-column-end: 6;
        grid-row-start: 2;
        grid-row-end: 3;
    }
`

const Photo = styled.div`

    height: ${props => props.height}px;
    width: ${props => props.width}px;
    @media screen and (max-width: ${bp.mobile}) {
        height: ${props => props.height / 2.5}px;
        width: ${props => props.width / 2.5}px;
    }
`

export default PhotoCollege