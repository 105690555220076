import React from 'react'
import styled from 'styled-components'
import { bp } from '../styles/colors'
import SectionDescription from './sectionDescription'
import ServiceCard from './serviceCard'
import houseIcon from "../icons/houseIcon.svg"
import saneerausIcon from "../icons/saneerausIcon.svg"
import kalustoIcon from "../icons/kalustoIcon.svg"
import kaivuriIcon from "../icons/kaivuriIcon.svg"
import SectionTitle from './sectionTitle'



const ServiceSection = (props) => {
    return (
      <SectionWrapper id="serviceSection">
          <BreakpointContainer>
          {
                props.title == null
                ? <></>
                : 
                <SectionTitle
                    title = "Palvelut"
                />
            }
        <SectionContainer>
            <SectionDescription
                smallTitle="Etelä-Suomen alueella"
                mediumTitle="Laadukasta rakentamista"
                blackText="Toteutamme rakennusurakoita monipuolisesti erityisesti Lahden alueella. Lisäksi tarjoamme yhteistyöverkostomme kautta maanrakennus-, lvi- ja sähkötyöt sekä arkkitehti- ja vastaavan työnjohdon palveluita. Avaimet käteen -periaatteella saat meiltä kaiken tarvitsemasi projektiisi."
                buttonDesc="Ota yhteyttä"
                buttonTo="#contactSection"
                internalButton={false}
            />
            <CenteringWrapper>
            <CardWrapper>
                <TwoCardContainer>
                    <ServiceCard
                        origin={props.origin}
                        link="/palvelut/uudisrakentaminen"
                        icon={houseIcon}
                        alt="Uudisrakentaminen"
                        title="Uudisrakentaminen"
                        text="Rakennamme omakotitalot, kesämökit, autotallit ja -katokset sekä hallit perustuksista viimeiseen listaan. Teemme myös pihatyöt!"
                    />
                    <ServiceCard
                    origin={props.origin}
                        link="/palvelut/saneeraus"
                        icon={saneerausIcon}
                        alt="Saneeraus"
                        title="Saneeraus"
                        text="Toteutamme ammattitaidolla kaikenlaiset remontit ja saneeraukset sekä omakotitaloissa että taloyhtiöissä."
                    />
                </TwoCardContainer>
                <TwoCardContainer>
                <ServiceCard
                origin={props.origin}
                        link="/palvelut/maanrakentaminen"
                        icon={kaivuriIcon}
                        alt="Maanrakentaminen"
                        title="Maanrakentaminen"
                        text="Ketterällä kalustolla hoidamme haastavammatkin maarakennustyöt perustuksista ja maansiirrosta kivetyksiin."
                    />

                    <ServiceCard
                    origin={props.origin}
                        link="/vuokraamo"
                        icon={kalustoIcon}
                        alt="Kalustovuokraus"
                        title="Kalustovuokraus"
                        text="Vuokraamme toimivaa kalustoa kaikkiin rakennusalan töihin. Tarvittaessa toimitamme kaluston suoraan kohteeseen."
                    />
                </TwoCardContainer>
            </CardWrapper>
            </CenteringWrapper>
        </SectionContainer>
        </BreakpointContainer>
    </SectionWrapper>
    )

}

const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem 5rem 10rem 5rem;
    gap: 3rem;


    @media screen and (max-width: ${bp.mobile}) {
        margin: 0rem 1rem 5rem 1rem;
        gap: 1rem;
    }
`

const SectionContainer = styled.section`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5rem;
    width: 100%;
    max-width: ${bp.large};

`

const CenteringWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    @media screen and (max-width: ${bp.mobile}) {
        margin-top: -2rem;
    }
`

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5rem;
    justify-self: center;
    align-self: center;
    flex-grow: 1;
  

    @media screen and (max-width: ${bp.mobile}) {
        gap: 3rem;
    }
`

const TwoCardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    flex-wrap: wrap;

    @media screen and (max-width: ${bp.mobile}) {
        gap: 3rem;
    }
`

const BreakpointContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: ${bp.large};

`

export default ServiceSection